import { Link, graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"
import { motion } from "framer-motion"
import React from "react"
import parse from "html-react-parser"
import stopperClick from "../../images/stopper-click.png"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

const PostsGridContainer = styled.div`
  ${tw`container grid max-w-screen-xl grid-cols-1 gap-5 px-6 mx-auto my-5 lg:grid-cols-3 sm:grid-cols-2 md:px-12`}
`
const PostCard = styled(motion.div)`
  ${tw`relative mb-5 cursor-pointer `}
`
const PostCardImage = styled(motion.div)`
  ${tw`relative overflow-hidden rounded-none sm:h-25vw h-50vw aspect-w-1 aspect-h-1`}
  transition-timing-function: cubic-bezier(0.60, 0.01, 0.05, 0.90);

  & .case-sticker {
    ${tw`absolute left-auto z-10 w-20 h-20 pointer-events-none right-2 top-2`}
  }
  .blog-featured-image::after {
    //black opacity 0.5 overlay
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
`
const PostCardHeadline = styled.div`
  ${tw`py-2 font-black text-gray-800 pointer-events-none font-primary text-32`}
`
const PostCardSubline = styled.div`
  ${tw`italic text-gray-700 pointer-events-none font-copy xl:text-14 lg:text-12 text-14`}
`
const DisplayHeadline = styled.div`
  ${tw`px-5 text-2xl font-black text-center text-gray-800 pointer-events-none 2xl:py-8 lg:text-4xl fhd:text-5xl font-primary`}
`
const DisplaySubline = styled.div`
  ${tw`relative px-5 pt-2 text-xl text-center text-white 2xl:pt-0 lg:text-2xl fhd:text-32 font-display`};
`

export default function WpGlossarsArchive() {
  const data = useStaticQuery(graphql`
    {
      allWpGlossar(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            slug
            uri
            title
            excerpt
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const stopperAni = {
    init: { opacity: 0, rotate: 20, ease: "easeInOut", duration: 0.3 },
    ani: {
      opacity: 1,
      rotate: 0,
      transition: {
        duration: 0.25,
        ease: "easeInOut",
      },
    },
  }
  const zoomImage = {
    init: { scale: 1, transition: { ease: "easeInOut", duration: 0.2 } },
    ani: {
      scale: 1.02,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  }

  return (
    <section>
      <PostsGridContainer>
        {data.allWpGlossar.edges.map(({ node }) => {
          return (
            <PostCard
              key={node.slug}
              className="case-card"
              whileTap="clicked"
              layoutId={`${node.slug}`}
              transition={{
                duration: 0.5,
                ease: [0.3, 0.01, 0.26, 0.9],
              }}
            >
              <Link to={`${node.uri}`}>
                <PostCardImage whileHover="ani" animate="init" initial="init">
                  <motion.img className="case-sticker" variants={stopperAni} src={stopperClick} alt="sticker" />

                  <motion.div variants={zoomImage} className="object-cover w-full h-full pointer-events-none zoom-image">
                    {node.featuredImage?.node?.localFile ? (
                      <GatsbyImage image={getImage(node.featuredImage.node?.localFile)} alt="post-tile" className="object-cover w-full h-full blog-featured-image" />
                    ) : (
                      <div className="w-full h-full bg-black"></div>
                    )}
                    <motion.div variants={zoomImage} className="absolute top-0 left-0 flex flex-col items-center justify-center object-cover w-full h-full">
                      <DisplayHeadline className="!text-white uppercase card-headline">{parse(node.title?.replace("<br>", ""))}</DisplayHeadline>
                      {node.excerpt && <DisplaySubline>{parse(node.excerpt)}</DisplaySubline>}
                    </motion.div>
                  </motion.div>
                </PostCardImage>
              </Link>
            </PostCard>
          )
        })}
      </PostsGridContainer>
    </section>
  )
}
