import tw, { styled } from 'twin.macro';

import React from "react"

const H1 = styled.h1`
    ${tw`
        font-primary
        font-black
        text-black
        lg:text-8xl
        sm:text-5xl
        text-3xl
        text-center
        uppercase
    `}
`;
const H2 = styled.h2`
    ${tw`
        font-primary
        font-black
        text-black
        lg:text-5xl
        sm:text-3xl
        text-xl
        text-center
        uppercase
    `}
`;
const H3 = styled.h3`
    ${tw`
        font-primary
        font-black
        text-black
        lg:text-3xl
        sm:text-xl
        text-lg
        text-center
        uppercase
    `}
`;

function Heading1({children}) {
    return (
        <H1>{children}</H1>
    )
}
function Heading2({children}) {
    return (
        <H2>{children}</H2>
    )
}
function Heading3({children}) {
    return (
        <H3>{children}</H3>
    )
}

export {
    Heading1,
    Heading2,
    Heading3
}
